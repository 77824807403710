<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Delivery Charges</div>
          <delivery-charge-action
            v-if="permissions.create"
            id="addCharge"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </delivery-charge-action>
        </div>
        <div class="card-body">

          <!--list-->
          <div class="table-responsive">
            <table class="table custom-table">
              <thead>
              <tr>
                <th>Max Distance</th>
                <th>Charge</th>
                <th>Max Order</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="charge in charges" :key="charge.id">
                  <td>{{ charge.distance }}</td>
                  <td>{{ currencySymbol }} {{ charge.amount }}</td>
                  <td>{{ currencySymbol }} {{ charge.max_order }}</td>
                  <td>
                    <span
                      v-if="permissions.update"
                      class="action list-action"
                      type="button"
                      @click="changeCurrentItem(charge)"
                    >
                      <i class="icon-edit1"></i>
                    </span>
                    <span
                      v-if="permissions.delete"
                      class="action list-action"
                      type="button"
                      @click="changeCurrentItem(charge, false)"
                    >
                      <i class="icon-delete_sweep"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--actions-->
          <delivery-charge-action
            id="editDeliveryCharge"
            :charge="actionSubject.subject"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />

          <delete-action
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            confirm-body="This will delete the rule permanently"
            endpoint="/admin/delivery-charge"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import { CURRENCY_SYMBOL, LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';
import DeliveryChargeAction from '../DoActions/DeliveryChargeAction';

export default {
  name: "delivery-charge-page-content",
  props: {
    charges: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {DeliveryChargeAction, DeleteAction, BadgeComp},
  data() {
    return {
      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      },
      currencySymbol: CURRENCY_SYMBOL
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {

    changeCurrentItem(subject, editing = true) {

      if (editing) {
        this.actionStatus.edit = true;
        this.actionSubject.action = 'edit';
      } else {
        this.actionStatus.delete = true;
        this.actionSubject.action = 'delete';
      }

      this.actionSubject.subject = subject;
    }

  }
}
</script>

<style>
.table-badge {
  font-size: 12px;
}

.custom-table tr td {
  font-size: 15px;
  font-weight: bold;
}
</style>
