<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Offers</div>
          <offer-action
            v-if="permissions.create"
            id="addOffer"
            :offer-types="offerTypes"
            :order-types="orderTypes"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </offer-action>
        </div>
        <div class="card-body">

          <!--                    list-->
          <data-table
            id="offers"
            :column-map="columnMap"
            :data="offers"
            @actionClicked="handleAction"
          />

          <!--                    actions-->
          <offer-action
            id="editOffer"
            :offer="actionSubject.subject"
            :offer-types="offerTypes"
            :order-types="orderTypes"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />


          <delete-action
            :confirm-body="`This will delete the '${actionSubject.subject.type}: ${actionSubject.subject.amount}' permanently.`"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/offer"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferAction from '../DoActions/OfferAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import DataTable from '../Utility/DataTable';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';
import { formatDate } from '../../js/helpers/Helper';

export default {
  name: "offers-page-content",
  props: {
    offers: {
      type: Array,
      required: true
    },
    offerTypes: {
      type: Array,
      required: true
    },
    orderTypes: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {OfferAction, DeleteAction, BadgeComp, DataTable},
  data() {
    return {
      columnMap: [
        {data: 'type', title: 'Type', render: this.renderType},
        {data: 'code', title: 'Code'},
        {data: 'amount', title: 'Amount', render: this.renderAmount},
        {data: 'min_order', title: 'Order Min.', render: data => data.toFixed(2)},
        {data: 'first_time', title: 'First Time', render: (data) => (data) ? 'Yes':'No'},
        {data: 'once', title: 'Once', render: (data) => (data) ? 'Yes':'No'},
        {data: 'to', title: 'Till', render: this.renderDate},
        {data: 'sort', title: 'Sort'},
        {data: 'show', title: 'Show', render: this.renderShow},
        {data: 'status', title: 'Status', render: this.renderStatus},
        {data: 'id', title: 'Actions', render: this.renderAction},
      ],

      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.offers.find(itm => itm.id === id),
        action: action,
      };
    },

    renderType(data) {

      if (data == 'discount') {
        return `<span class="badge table-badge badge-light badge-pill">${data}</span>`
      }

      return `<span class="badge table-badge badge-white badge-pill">${data}</span>`
    },

    renderShow(data) {

      if (data) {
        return `<span class="badge table-badge badge-primary badge-pill">Shown</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Hidden</span>`
    },

    renderStatus(data) {

      if (data) {
        return `<span class="badge table-badge badge-primary badge-pill">Enabled</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Disabled</span>`
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param data
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(data, type, row) {

      let actions = '';

      if (this.permissions.update) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-edit1"></i>
          </span>
        `;
      }

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      return actions;
    },

    renderDate(data) {
      return formatDate(data);
    },

    renderAmount(data, d, row) {
      const price = data.toFixed(2);
      return (row.fixed) ? price : `${price} %`;
    }
  }
}
</script>

<style>
.table-badge {
  font-size: 12px;
}
</style>
