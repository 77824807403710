<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Menu Items</div>
          <item-action
            v-if="permissions.create"
            id="addItem"
            :addons="addons"
            :categories="categories"
            :items="items"
            :item-types="itemTypes"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </item-action>
        </div>
        <div class="card-body">

          <!--List-->
          <data-table
            id="items"
            :column-map="columnMap"
            :data="items"
            @actionClicked="handleAction"
          />

          <!--Actions-->
          <delete-action
            :confirm-body="`
                            '${actionSubject.subject.name}' will be deleted
                            ${actionSubject.subject.is_group
                                ? '& all items under this group will leave the group'
                                :' permanently'}.
                        `"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/menu/items"
            @cancel="()=>this.actionStatus.delete = false"
          />

          <item-action
            id="editItem"
            :addons="addons"
            :categories="categories"
            :item="actionSubject.subject"
            :item-types="itemTypes"
            :items="items"
            :show="actionStatus.edit"
            @user-action-hide="()=>this.actionStatus.edit = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemAction from '../DoActions/ItemAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';
import DataTable from '../Utility/DataTable';

export default {
  name: "items-page-content",
  props: {
    items: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    addons: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {ItemAction, DeleteAction, BadgeComp, DataTable},
  data() {
    return {
      columnMap: [
        {data: 'name', title: 'Name'},
        {data: 'category', title: 'Category', render: this.renderCategory},
        {data: 'group', title: 'Group', render: this.renderGroup},
        {data: 'price', title: 'Price', render: (price) => price.toFixed(2)},
        {data: 'sort', title: 'Sort'},
        {data: 'status', title: 'Status', render: this.renderBadge},
        {data: 'id', title: 'Actions', render: this.renderAction},
      ],

      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {
    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.items.find(itm => itm.id === id),
        action: action,
      };
    },

    renderCategory(category) {
      return category ? category.name : '';
    },

    renderBadge(data, type, row) {

      if (data == 1) {
        return `<span class="badge table-badge badge-primary badge-pill">Enabled</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Disabled</span>`
    },

    renderGroup(group, type, row) {
      // if this is a group
      if (row.is_group) {

        return `<span class="badge table-badge badge-primary badge-pill">Main Group</span>`;
      }

      // if items has group
      if (group) {
        return group.name;
      }

      return '';
    },

    /**
     * RETURNING ELEMENT MUST HAVE CLASS ".action" AND
     * ATTRIBUTE "data-id" & "data-action"  FOT IT TO WORK
     * @returns {string}
     * @param id
     */
    renderAction(id) {

      let actions = '';

      if (this.permissions.update) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${id}"
          >
            <i class="icon-edit1"></i>
          </span>
        `;
      }

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${id}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }


      return actions;
    }
  }
}
</script>

<style scoped>

</style>
