<template>
  <div class="Dashboard">
    <!--  Statistics  -->
    <div class="row gutters">
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <tile-card
          :content="stats.newOrder"
          icon="icon-shopping-bag1"
          icon-bg="bg-info"
          title="New Orders"
        >
        </tile-card>

      </div>

      <div class="col-lg-3 col-md-3 col-sm-3 col-6">

        <tile-card
          :content="stats.totalPrice | currencySymbol"
          icon="icon-slack"
          icon-bg="bg-danger"
          title="Sales"
        >
        </tile-card>

      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">

        <tile-card
          :content="stats.totalOrder"
          icon="icon-shopping-bag1"
          icon-bg="bg-warning"
          title="Orders"
        >
        </tile-card>

      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">

        <tile-card
          :content="stats.totalCustomer"
          icon="icon-user1"
          icon-bg="bg-success"
          title="Customers"
        >
        </tile-card>

      </div>
    </div>
    <!--  /Statistics  -->

    <!--   Order  -->
    <div class="row gutters">
      <div class="col-12">
        <!-- Card start -->
        <div class="card">
          <div class="card-header card-header-with-toggle-btn">
            <div class="card-title">New Orders</div>
            <div class="d-flex">
              <span class="mr-2">Sound</span>
              <switch-comp
                class="sound-switch"
                :checked="newOrderSound"
                enable-text="Enabled"
                disable-text="Disabled"
                @change="newOrderSound=!newOrderSound"
              />
            </div>
          </div>
          <div class="card-body">
            <no-order
              v-if="!allOrders.length"
              text="You have served all Orders."
              title="no order in queue"
              icon="icon-assignment_turned_in text-grey"
            >

            </no-order>
            <div v-else class="order-item">
              <div class="order-container">
                <div class="order-header">
                  <div class="content">Customer</div>
                  <div class="content requested-time">Requested For</div>
                  <div class="content status">Payment Status</div>
                  <div class="content">Total</div>
                </div>

                <div v-for="(order, index) in allOrders" :key="'product'+index" class="order-contents">
                  <div class="content customer">
                    <div class="user-avatar">
                      <img :src="userAvatar(order.customer_name)" alt="Apple iPhone 11" class="circle">
                    </div>
                    <div class="customer-info">
                      <div class="name">{{ order.customer_name }}</div>
                      <div class="phone">{{ order.customer_phone }}</div>
                    </div>
                  </div>
                  <div class="content requested-time">
                    <p
                      v-if="order.requested_time_is_asap"
                      class="sold text-info"
                    >
                      ASAP
                    </p>
                    <p
                      v-else
                      class="sold text-info"
                    >
                      {{ order.requested_delivery_time | formatDate }}
                    </p>
                  </div>
                  <div class="content status">
                    <p :class="order.status_payment? 'btn-success' : 'btn-danger'" class="btn text-uppercase">
                      {{ order.status_payment | paidUnpaidStatus }}</p>
                  </div>
                  <div class="content price">
                    <div class="sold text-info">{{ order.total | currencySymbol }}</div>
                    <div class="sold-title">
                      <span @click="selectForUpdate('order', order)" class="badge text-uppercase cursor-pointer"
                            :class="badgeColor(order.status)">{{ order.status }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card end -->
      </div>
      <!--   /Order   -->
    </div>
    <!--  Order  -->

    <!--  Activities and Other  -->
    <div class="row gutters">
      <!-- Reservations -->
      <div class="col-lg-7 col-md-7 col-sm-7 col-12">
        <!-- Card start -->
        <div class="card">
          <div class="card-header">
            <div class="card-title">Reservations</div>
          </div>
          <div class="card-body">
            <no-order
              v-if="!reservations.length"
              text="You have Confirmed all pending Reservation"
              title="No Pending Reservations"
            >
            </no-order>
            <div v-if="reservations.length" class="reservation-container">
              <div class="reservation">

                <div class="reservation-header">
                  <div class="content customer">Name</div>
                  <div class="content requested-for">Requested For</div>
                  <div class="content person">Person</div>
                  <div class="content status">Status</div>
                </div>

                <div v-for="(reservation, index) in reservations" :key="'reservation'+ index"
                     class="reservation-contents">
                  <div class="content customer">
                    <div class="name">{{ reservation.name }}</div>
                    <div class="phone">{{ reservation.phone }}</div>
                  </div>
                  <div class="content requested-for">
                    <div class="request">{{ reservation.datetime | formatDate }}</div>
                  </div>
                  <div class="content person">
                    <div class="reservation-person">{{ reservation.person }}</div>
                  </div>
                  <div class="content status">
                    <span class="btn btn-dark text-uppercase cursor-pointer"
                          @click="selectForUpdate('reservation', reservation)">{{ reservation.status }}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- Card end -->
        </div>
      </div>

      <!--  Activities    -->
      <div class="col-lg-5 col-md-5 col-sm-5 col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Activities</div>
          </div>
          <div class="card-body">
            <no-order
              text="No Activities to Show"
              title="Everything is looks clear"
              icon="icon-archive text-warning"
            >
            </no-order>
            <div v-show="false" class="timeline-activity">
              <div class="activity-log">
                <p class="log-name">Corey Haggard<small class="log-time">- 9 mins ago</small></p>
                <div class="log-details">Tycoon dashboard has been created<span class="text-info ml-1">#New</span>
                </div>
              </div>
              <div class="activity-log">
                <p class="log-name">Gleb Kuznetsov<small class="log-time">- 4 hrs ago</small></p>
                <div class="log-details">
                  Farewell day photos uploaded.
                  <div class="stacked-images mt-1">
                    <img alt="Profile Image" class="sm" src="img/user.png">
                    <img alt="Profile Image" class="sm" src="img/user2.png">
                    <img alt="Profile Image" class="sm" src="img/user3.png">
                    <img alt="Profile Image" class="sm" src="img/user4.png">
                    <span class="plus sm">+5</span>
                  </div>
                </div>
              </div>
              <div class="activity-log">
                <p class="log-name">Yuki Hayashi<small class="log-time">- 7 hrs ago</small></p>
                <div class="log-details">Developed 30 multipurpose Bootstrap 4 Admin Templates</div>
              </div>
              <div class="activity-log">
                <p class="log-name">Nathan James<small class="log-time">- 9 hrs ago</small></p>
                <div class="log-details">Best Design Award</div>
              </div>
              <div class="activity-log">
                <p class="log-name">Elon Musk<small class="log-time">- 4 hrs ago</small></p>
                <div class="log-details">
                  Farewell day photos uploaded.
                  <div class="stacked-images mt-1">
                    <img alt="Profile Image" class="sm" src="img/user5.png">
                    <img alt="Profile Image" class="sm" src="img/user22.png">
                    <span class="plus sm">+7</span>
                  </div>
                </div>
              </div>
              <div class="activity-log">
                <p class="log-name">Nkio Toyoda<small class="log-time">- 3 hrs ago</small></p>
                <div class="log-details">Developed 30 multipurpose Bootstrap 4 Admin Templates</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  /Activities    -->

      <!--  /Activities and Other  -->
    </div>

    <status-update-action
      :title="getModalTitle"
      :subject-id="subject.id"
      :available-options="options"
      :current-option="subject.status"
      :action-link="statusUpdateUrl"
      :show-modal="isUpdating"
      @user-action-hide="handleHideModal()"
    >
    </status-update-action>

    <sound-comp
      :play="newOrderSoundPlay"
      :enabled="newOrderSound"
      :loop="true"
      :loop-interval="2000"
      file-name="order_sound_1.mp3"
    >
      <template #play="sProps"></template>
    </sound-comp>

  </div>
</template>
<script>
import { CURRENCY_SYMBOL } from "../../js/helpers/Constants";
import { formatDate } from '../../js/helpers/Helper';
import noOrder from "../Utility/card/noOrder";
import tileCard from '../Utility/card/tileCard';
import statusUpdateAction from '../DoActions/StatusUpdateAction'
import SwitchComp from '../Utility/SwitchComp';
import SoundComp from '../Utility/SoundComp';

export default {
  name: 'dashboard-page-content',
  components: {SoundComp, SwitchComp, noOrder, tileCard, statusUpdateAction},
  props: {
    totalOrder: {
      required: true,
      type: Number
    },
    totalPrice: {
      required: true,
      type: Number
    },
    newOrder: {
      required: true,
      type: Number,
    },
    totalCustomer: {
      required: true,
      type: Number,
    },
    orders: {
      type: Array || Object,
      required: String
    },
    reservations: {
      type: Array || Object,
      required: true,
    },
    orderStatusOptions: {
      type: [Array, Object],
      required: true
    },
    reservationStatusOptions: {
      type: [Array, Object],
      required: true,
    }
  },
  data() {
    return {
      newOrderSound: true,
      newOrderSoundPlay: false,

      updating: {
        reservation: false,
        order: false
      },

      stats: {
        totalOrder: this.totalOrder,
        totalPrice: this.totalPrice,
        totalCustomer: this.totalCustomer,
        newOrder: this.newOrder,
      },

      allOrders: [...this.orders],

      subjectType: null,
      subject: {}
    };
  },
  computed: {
    getModalTitle() {
      return this.subjectType === 'order' ? "Update Order Status" : 'Update Reservation Status'
    },
    options() {

      if (!this.subjectType) return []
      if (this.subjectType === 'order') return this.orderStatusOptions
      return this.reservationStatusOptions
    },

    statusUpdateUrl() {
      return this.subjectType === 'order' ? `admin/order` : `admin/reservation`
    },
    isUpdating() {
      return this.updating.reservation || this.updating.order
    }
  },
  filters: {
    currencySymbol(amount) {
      return `${CURRENCY_SYMBOL}${amount}`
    },
    formatDate(date) {
      return formatDate(date, 'MMM DD  @ hh:mm A')
    },
    paidUnpaidStatus(status) {
      if (status) return 'paid'

      return 'due'
    }
  },
  mounted() {
    this.listenForNewOrder();
  },
  methods: {
    handleHideModal() {
      this.updating.reservation = false;
      this.updating.order = false;
      this.subjectType = null
      this.subject = {}
    },

    selectForUpdate(type, item) {
      if (type === 'reservation') {
        this.updating.reservation = true
        this.subjectType = type
      }

      if (type === 'order') {
        this.updating.order = true
        this.subjectType = type
      }

      this.subject = item
    },

    userAvatar(name) {
      let userName = name.replaceAll(' ', '+').toLowerCase();
      return `https://ui-avatars.com/api/bold=true??background=ff1e1e?name=${userName}`
    },

    badgeColor(status) {

      let badgeClass = '';

      switch (status) {

        case 'pending':
          badgeClass = 'badge-dark';
          break;

        case 'confirmed':
          badgeClass = 'badge-success';
          break;

        case 'cancelled':
          badgeClass = 'badge-secondary';
          break;

        default:
          badgeClass = 'badge-danger';
      }

      return badgeClass;
    },

    listenForNewOrder() {
      Echo
        .private('orderCh.new-order')
        .listen('.order.new', e => {
          this.addNewlyAddedOrder(e);
        })
      ;
    },

    addNewlyAddedOrder({order}) {
      if (!order) return false;

      // increase new order
      this.stats.newOrder += 1;

      // add this order to orders
      this.allOrders.unshift(order);

      // enable the sound
      this.triggerNewOrderSound();
    },

    triggerNewOrderSound() {
      this.newOrderSoundPlay = true;

      setTimeout(()=>this.newOrderSoundPlay=false, 100);
    }
  },
}
</script>

<style scoped lang="scss">
 .card-header-with-toggle-btn {
   display: flex;
   justify-content: space-between;

   .sound-switch {
     margin-top: 3px;
     width: 90px;
   }
 }
</style>
