<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "popup-list-item-select",
  props: {
    items: {
      type: Array,
      required: true
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    openStatus: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      open: false,
      selected: ''
    }
  },

  computed: {
    openStatus: (nv) => {
      this.open = nv;
    }
  },

  methods: {
    selectOption() {

    }
  }
}
</script>

