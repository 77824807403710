<template>
  <div class="table-container">
    <div v-if="title" class="t-header">{{ title }}</div>
    <div class="table-responsive">
      <table :id="tableId" class="table custom-table"></table>
    </div>
  </div>
</template>

<script>
import 'datatables.net';
import '../../js/plugins/datatables/dataTables.bootstrap.min';
import '../../js/plugins/datatables/buttons.min';
import '../../js/plugins/datatables/html5.min';
import '../../js/plugins/datatables/buttons.print.min';

//@TODO: check following todos for required parameters for actions
export default {
  name: "data-table",
  props: {
    title: String,
    id: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    columnMap: {
      type: [Array, Boolean],
      default: false
    },
    perPage: { // show n records per pare
      type: Number,
      default: 10
    },
    showAll: { // show all records
      type: Boolean,
      default: false
    },
    enableDataExport: {
      type: Boolean,
      default: false
    },
    clickActionClass: {
      type: String,
      default: '.action'
    }
  },
  data() {
    return {
      table: null,
      tableBody: null
    }
  },
  computed: {
    showPerPage() {
      return this.showAll ? -1 : this.perPage;
    },
    domValue() {
      return this.enableDataExport ? 'Bfrtip' : 'lfrtip';
    },
    columns() {
      if (!this.columnMap) {
        const keys = Object.keys(this.data[0]);

        return keys.map(k => ({
          data: k,
          title: `${k[0].toUpperCase()}${k.substr(1)}`
        }));
      }

      return this.columnMap;
    },
    tableId() {
      return `${this.id}-table`;
    }
  },
  watch: {
    data() {
      this.updateTable();
    }
  },
  mounted() {
    this.initTable();
  },
  destroyed() {
    // unregister all events
    this.tableBody.off('click');
  },
  methods: {
    initTable() {
      const tableSelector = `#${this.tableId}`;

      // initialize the table
      this.table = $(tableSelector).DataTable({
        dom: this.domValue,
        buttons: [
          'copyHtml5',
          'csvHtml5',
          'print'
        ],
        iDisplayLength: 5,
        pageLength: this.showPerPage,
        order: [],
        data: this.data,
        columns: this.columns
      });

      this.tableBody = $(`${tableSelector} tbody`);

      // make rows selectable
      this.tableBody.on('click', 'tr', this.handleRowHighlight);

      //@TODO: CLASS "clickActionClass" MUST PRESENT IN ACTION
      //@TODO: "data-action-value" & "data-action" MUST BE PRESENT IN ACTION
      this.tableBody.on('click', this.clickActionClass, this.handleActionClick);
    },

    updateTable() {
      this.table
        .clear()
        .rows.add(this.data)
        .draw()
      ;
    },

    handleRowHighlight(e) {
      const actionClassName = this.clickActionClass.replace('.', '');
      const targetIsAction = e.target.classList.contains(actionClassName);
      const parentIsAction = e.target.closest(this.clickActionClass)?.classList?.contains(actionClassName);

      // don't select the row if action or it's icon is clicked
      if (!targetIsAction && !parentIsAction) {
        $(e.currentTarget).toggleClass('selected');
      }
    },

    handleActionClick(e) {
      const id = $(e.currentTarget).data('action-value');
      const action = $(e.currentTarget).data('action');

      if (!id || !action) {
        throw new Error(`Action should have "data-id" & "data-action" attribute`);
      }

      this.processAction({id, action});
    },

    processAction(payload) {
      this.$emit('actionClicked', payload);
    },

  }
}
</script>

<style>
table.table {
  width: 100% !important;
}

div[id$="-table_length"] {
  float: left !important;
}

.table-container {
  margin-bottom: 0;
}
</style>
