<template>
  <div
    v-if="error"
    class="form-error-mag text-danger text-capitalize"
  >
    <i class="icon-error"></i>
    {{ error }}
  </div>
</template>

<script>
export default {
  name: "form-error-msg",
  props: {
    error: {
      type: [String, Boolean],
      required: true
    }
  }
}
</script>

<style scoped>
.form-error-mag {
  margin: 5px 0;
}
</style>
