<template>
    <div>
        <slot :open-modal="openModal"></slot>

        <modal-comp
            :id="id"
            :show="showModal"
            :title="modal.title"
            :header-classes="modal.headerClasses"
            :close-btn-classes="modal.closeBtnClasses"
            @submit="handleSubmit"
            @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
        >
            <form class="row">
                <div class="form-group col-12">
                    <label for="categoryName" class="col-form-label">
                        Category Name:
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="categoryName"
                        placeholder="Item category name"
                        v-model.trim="form.categoryName"
                        autocomplete="off"
                        required
                    >
                    <form-error-msg :error="formError.categoryName" />
                </div>
                <div class="form-group col-12">
                    <label for="parent" class="col-form-label">
                        Parent:
                    </label>
                    <form-input-help>Select a parent only if you want to create subcategory</form-input-help>
                    <treeselect
                        id="parent"
                        placeholder="Select a parent category"
                        v-model="form.select.parent"
                        :options="form.select.options"
                        :multiple="false"
                        :searchable="false"
                        :clearable="true"
                        :disabled="category.is_parent"
                    />
                    <form-error-msg :error="formError.parent" />
                </div>

                <div class="form-group col-6">
                    <label for="sort" class="col-form-label">
                        Sort:
                        <span class="text-danger">*</span>
                    </label>
                    <form-input-help>Works on descending order</form-input-help>
                    <input
                        type="number"
                        class="form-control"
                        id="sort"
                        placeholder="Sort position (descending)"
                        v-model.trim="form.sort"
                        autocomplete="off"
                        required
                    >
                    <form-error-msg :error="formError.sort" />
                </div>

                <div class="form-group col-6">
                    <label for="sort" class="col-form-label">
                        Enable Category:
                        <span class="text-danger">*</span>
                    </label>
                    <div>
                        <form-input-help>Disabling will hide the category from users</form-input-help>
                        <switch-comp
                            :checked="form.status"
                            @change="val=>form.status=val"
                            enable-text="Enabled"
                            disable-text="Disabled"
                        />
                    </div>
                </div>
            </form>

            <template #submit-btn-content>
                <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
                {{ modal.submitBtnText }}
            </template>
        </modal-comp>
    </div>
</template>

<script>
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import SwitchComp from '../Utility/SwitchComp';

export default {
    name: "category-action",
    components: {FormErrorMsg, Treeselect, ModalComp, FormInputHelp, SwitchComp},
    props: {
        id: {
            type: String,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        category: {
            type: [Object,Boolean]
        },
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showModal: false,
            modal: {
                title: 'New Category',
                headerClasses: '',
                closeBtnClasses: '',
                submitBtnText: 'Add Category'
            },

            form: {
                categoryName: '',
                sort: 0,
                status: true,
                select: {
                    parent: null,
                    options: []
                },
                submitting: false
            },
            formError: {
                categoryName: false,
                parent: false,
                sort: false
            }
        };
    },

    validations: {
        form: {
            categoryName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            select: {
                parent: {
                    minLength: minLength(1)
                }
            },
            sort: {
                numeric: numeric
            }
        }
    },

    watch: {
        show: function (nv) {
            this.showModal = nv;
        },
        category: {
            immediate: true,
            handler() {
                this.populateForUpdate();
                this.makeParentCategorySelectOptions();
            }
        },
        // error handling
        'form.categoryName': function() {
            this.formError.categoryName = ErrorHelper.errAll(this.$v, 'form.categoryName');
        },
        'form.select.parent': function() {
            this.formError.parent = ErrorHelper.errAll(this.$v, 'form.select.parent');
        },
        'form.sort': function () {
            this.formError.sort = ErrorHelper.errAll(this.$v, 'form.sort');
        }
    },

    methods: {
        openModal() {
            this.showModal = true;
        },

        async handleSubmit() {

            // check if form has any error
            if (this.$v.$invalid) {

                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: 'Invalid Data',
                    body: 'Please fill the form correctly before submitting',
                    type: 'er'
                });

                return false;
            }

            const response = await this.sendData();

            if (response) {

                // show success notification
                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: `Category ${this.category ? 'Created' : 'Updated'}`,
                    body: `'${response.data.name}' category ${this.category ? 'created':'updated'} successfully`,
                    type: 'su',
                    autoHideDelay: 2,
                    closeCallback: () => window.location.reload()
                });

                // close modal
                this.showModal = false;
            }
        },

        async sendData() {

            this.form.submitting = true;

            const endPoint = (this.category)
                ? `/admin/menu/categories/${this.category.id}`
                : `/admin/menu/categories`
            ;
            const method = (this.category) ? 'patch' : 'post';

            const requestData = {
                categoryName: this.form.categoryName,
                parent: this.form.select.parent,
                sort: parseInt(this.form.sort),
                status: this.form.status ? '1' : '0'
            };

            try {

                const res = await axios[method](endPoint, requestData);

                this.form.submitting = false;
                return res;

            } catch (e) {

                this.form.submitting = false;

                // show notification about error
                NotificationHelper.showServerError(this.$snotify, e);

                // map errors to display in form
                ErrorHelper.mapServerError(this.formError, e);

                return false;
            }

        },

        makeParentCategorySelectOptions() {

            // emptying existing data
            this.form.select.options = [];

            this.categories.map(({id, name, parent_id}) => {

                // exclude self && sub categories
                if ((id !== this.category?.id) && !parent_id) {
                    this.form.select.options.push(
                        {
                            id,
                            label: name
                        }
                    );
                }

            });
        },

        populateForUpdate() {

            // only populate for updating
            if (!this.category) return false;

            // customize modal
            this.modal = {
                title: 'Update Category',
                headerClasses: 'bg-warning text-dark',
                closeBtnClasses: 'text-dark',
                submitBtnText: 'Save Changes'
            };

            // populate values
            this.form.categoryName = this.category.name;
            this.form.sort = this.category.sort;
            this.form.select.parent = this.category.parent_id;
            this.form.status = (this.category.status==1);
        }
    }
}
</script>

<style scoped>

</style>
