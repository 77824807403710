<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Reservations</div>
        </div>
        <div class="card-body pt-0">

          <div class="filters-panel">

            <div class="filters-controls row">
              <div class="form-group col-sm-12 col-md-12 col-lg-2">
                <label>Customer</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Customer Name"
                  v-model="filters.customer"
                  @keyup="triggerFilter"
                />
              </div>
              <div class="form-group col-sm-5 col-md-5 col-lg-2">
                <label>From</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="From Date"
                  v-model="filters.dateFrom"
                  @change="triggerFilter"
                />
              </div>
              <div class="form-group col-sm-5 col-md-5 col-lg-2">
                <label>To</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="To Date"
                  v-model="filters.dateTo"
                  @change="triggerFilter"
                />
              </div>
              <div class="form-group col-sm-2 col-md-2 col-lg-auto">
                <button
                  type="button"
                  class="btn btn-primary mt-4 text-nowrap"
                  @click="filterSetToday"
                >
                  Today
                </button>
              </div>
              <div class="form-group col-sm-10 col-md-9 col-lg-2">
                <label>Status</label>
                <select
                  class="form-control"
                  v-model="filters.status"
                  @change="triggerFilter"
                >
                  <option selected value="">Choose...</option>
                  <option
                    v-for="status in statuses"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-2 col-md-3 col-lg-auto">
                <button
                  type="button"
                  class="btn btn-secondary mt-4 text-nowrap"
                  @click="resetFilter"
                >
                  Reset
                </button>
              </div>
            </div>

            <div class="filters-summery row">
              <div class="col fs20">
                Total <strong>{{ filterTotalPeople }}</strong> persons in <strong>{{ filterTotalReservations }}</strong> reservations
              </div>
            </div>

          </div>

          <!--list-->
          <data-table
            id="orders"
            :column-map="columnMap"
            :data="allReservations"
            click-action-class=".reservation-action"
            @actionClicked="handleAction"
          />

          <!--actions-->
          <reservation-action
            id="editReservation"
            :reservation="actionSubject.subject"
            :show="actionStatus.edit"
            :permissions="permissions"
            @user-action-hide="actionStatus.edit = false"
          />

          <status-update-action
            title="Update Reservation Status"
            :show-modal="actionStatus.status && permissions.update"
            :available-options="statuses"
            :current-option="actionSubject.subject.status"
            :subjectId="actionSubject.subject.id"
            :status-type-order="false"
            actionLink="/admin/reservation"
            @user-action-hide="hideUpdateAction"
          >

          </status-update-action>

          <delete-action
            :confirm-body="`
                This will delete the order permanently.
                You can delete a category once it has no items.
              `"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/reservation"
            @cancel="()=>this.actionStatus.delete = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderAction from '../DoActions/OrderAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import DataTable from '../Utility/DataTable';
import ReservationAction from "../DoActions/ReservationAction";
import statusUpdateAction from '../DoActions/StatusUpdateAction'
import {
  CURRENCY_SYMBOL,
  LIST_DELETE_ACTION_TYPE,
  LIST_EDIT_ACTION_TYPE,
  LIST_PRINT_ACTION_TYPE,
  LIST_STATUS_CHANGE_ACTION_TYPE
} from '../../js/helpers/Constants';
import { formatDate } from '../../js/helpers/Helper';
import PrintComp from '../Utility/PrintComp';
import PopupListItemSelect from '../Utility/PopupListItemSelect';

export default {
  name: "reservation-page-content",
  props: {
    reservation: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {
    ReservationAction,
    PopupListItemSelect,
    PrintComp,
    OrderAction,
    DeleteAction,
    BadgeComp,
    DataTable,
    statusUpdateAction
  },
  data() {
    return {
      filters: {
        customer: '',
        dateFrom: null,
        dateTo: null,
        status: ''
      },
      filterTotalReservations: 0,
      filterTotalPeople: 0,

      allReservations: [],

      columnMap: [
        {data: 'name', title: 'Customer Name', render: this.renderData},
        {data: 'person', title: 'Person', render: this.renderData},
        // {data: 'items', title: 'Items', render: items => items.length},
        // {data: 'email', title: 'Email'},
        {data: 'phone', title: 'Phone', render: this.renderData},
        // {data: 'created_at', title: 'Ordered', render: this.renderFormattedTime},
        {data: 'datetime', title: 'Requested', render: this.renderFormattedTime},
        {data: 'status', title: 'Status', render: this.renderStatus},
        {data: 'id', title: 'Actions', render: this.renderAction}
      ],

      actionStatus: {
        status: false,
        edit: false,
        delete: false,
        print: false,
      },

      actionSubject: {
        subject: false,
        action: false
      }
    }
  },

  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_STATUS_CHANGE_ACTION_TYPE) {
        this.actionStatus.status = true;
      }

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }

      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }

      if (action === LIST_PRINT_ACTION_TYPE) {
        this.actionStatus.print = true;
        alert('Printing will be implemented soon');
      }
    },

    // update filter totals
    allReservations(nv) {
      this.filterTotalReservations = nv.length;
      const total = nv.reduce((pv, cv) => {
        return (pv + parseInt(cv.person));
      }, 0);

      this.filterTotalPeople = total;
    },
  },

  mounted() {
    this.triggerFilter();
  },

  methods: {
    hideUpdateAction(){
      this.actionStatus.status = false
    },

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.reservation.find(itm => itm.id === id),
        action: action,
      };
    },

    renderData(data, view, row) {
      return row.last_email_send_status
        ? `<div>${data}</div>`
        : `<div class="text-secondary">${data}</div>`
      ;
    },

    renderStatus(status, view, row) {

      // available statuses: 'pending','confirmed','cancelled',

      let badgeClass = '';

      switch (status) {
        case 'pending':
          badgeClass = 'badge-dark';
          break;

        case 'confirmed':
          badgeClass = 'badge-success';
          break;

        case 'cancelled':
          badgeClass = 'badge-secondary';
          break;

        default:
          badgeClass = 'badge-danger';
      }

      return `
                <span
                    class="badge table-badge ${badgeClass} badge-pill text-capitalize reservation-action"
                    data-action="${LIST_STATUS_CHANGE_ACTION_TYPE}"
                    data-action-value="${row.id}"
                    >${status}</span>
            `;
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param data
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(data) {

      let actions = `
        <span
          type="button"
          class="reservation-action list-action"
          data-action="${LIST_EDIT_ACTION_TYPE}"
          data-action-value="${data}"
        >
          <i class="icon-archive"></i>
        </span>
      `;

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="reservation-action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      return actions;
    },

    renderFormattedTime(timeString, view, row) {
      const time = formatDate(timeString, 'Do MMM @hh:mm A');

      return row.last_email_send_status
        ? `<div>${time}</div>`
        : `<div class="text-secondary">${time}</div>`
      ;
    },

    resetFilter() {
      this.filters = {
        customer: '',
        dateFrom: null,
        dateTo: null,
        status: ''
      };

      this.allReservations = [...this.reservation];
    },

    filterSetToday() {

      const today = moment().format('YYYY-MM-DD');

      this.filters.dateFrom = today;
      this.filters.dateTo = today;

      this.triggerFilter()
    },

    triggerFilter() {

      let filteredData = [...this.reservation];

      for (const filter in this.filters) {

        const filterValue = this.filters[filter];

        switch (filter) {
          case 'customer':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.name.toLowerCase().includes(filterValue.toLowerCase()));
            break;

          case 'status':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.status == filterValue);
            break;

          case 'dateFrom':

            if (!filterValue) break;

            const fromTime = `${filterValue} 00:00:00`;

            filteredData = filteredData.filter(d => new Date(d.created_at).getTime() >= new Date(fromTime).getTime());
            break;

          case 'dateTo':

            if (!filterValue) break;

            const toTime = `${filterValue} 23:59:59`;

            filteredData = filteredData.filter(d => new Date(d.created_at).getTime() <= new Date(toTime).getTime());

            break;

          default:
            break;
        }

      }

      this.allReservations = filteredData;
    }

  },
}
</script>

<style>
.table-badge {
  font-size: 12px;
}

#orders-table td {
  font-size: 16px;
  font-weight: 600;
}

.reservation-action {
  cursor: pointer;
}
</style>
<style scoped lang="scss">
.filters-panel {
  margin: 0 -5px;

  .filters-controls {
    background-color: #dddddd;
    padding: 5px 15px;
  }

  .filters-summery {
    background-color: #ededed;
    padding: 5px 15px;
  }
}

@media screen and (max-width:576px) {
  .filters-panel button {
    display: block;
    width: 100%;
  }
}
</style>
