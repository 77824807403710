<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="modalId"
      :show="showModal"
      :title="modalTitle"
      :header-classes="modalHeaderClasses"
      :close-btn-classes="modalCloseBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
                this.showModal=false;
                this.$emit('group-action-hide', modalId);
            }"
    >
      <form>
        <div class="form-group">
          <label for="groupName" class="col-form-label">
            Group Name:
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="groupName"
            v-model.trim="form.group"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.group" />
        </div>
        <div class="form-group">
          <label for="permissions" class="col-form-label">Permissions:</label>
          <treeselect
            id="permissions"
            placeholder="Select Allowed Permissions"
            v-model="form.select.permissions"
            :options="form.select.options"
            :multiple="true"
            :searchable="true"
            :clearable="true"
            :disableBranchNodes="true"
            :showCount="true"
          />
          <form-error-msg :error="formError.permissions" />
        </div>
        <div class="form-group">
          <label for="description" class="col-form-label">
            Description:
            <span class="text-danger">*</span>
          </label>
          <textarea
            class="form-control"
            id="description"
            v-model="form.description"
          ></textarea>
          <form-error-msg :error="formError.description" />
        </div>
      </form>

      <template #submit-btn-content>
        <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
        {{ submitBtnText }}
      </template>
    </modal-comp>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import ErrorHelper, { hasError, isAllEmpty } from '../../../js/helpers/ErrorHelper';
import FormErrorMsg from '../../Utility/FormErrorMsg';
import NotificationHelper from '../../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../../Utility/ModalComp';

export default {
  name: "group-action",
  components: {FormErrorMsg, Treeselect, ModalComp},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modalId: {
      type: String,
      required: true
    },
    permissions: { // required for adding
      type: [Object, Array],
      required: true
    },
    group: { // required for updating
      type: [Object, Boolean],
      default: false
    }
  },

  data: function () {
    return {
      showModal: false,
      modalTitle: 'Add New Group',
      modalHeaderClasses: '',
      modalCloseBtnClasses: '',
      submitBtnText: 'Add Group',

      form: {
        group: '',
        select: {
          permissions: [],
          options: []
        },
        description: '',
        submitting: false
      },
      formError: {
        group: false,
        permissions: false,
        description: false
      }
    };
  },

  validations: {
    form: {
      group: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(20)
      },
      select: {
        permissions: {
          required,
          minLength: minLength(1)
        }
      },
      description: {
        maxLength: maxLength(50)
      }
    }
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
    // error handling
    'form.group': function () {
      this.formError.group = ErrorHelper.errAll(this.$v, 'form.group');
    },
    'form.select.permissions': function () {
      this.formError.permissions = ErrorHelper.errAll(this.$v, 'form.select.permissions');
    },
    'form.description': function (nv) {
      this.formError.description = ErrorHelper.errAll(this.$v, 'form.description');
    }
  },

  mounted() {
    // props validation
    this.validateProps();

    // prepare permissions to be used with select
    this.form.select.options = this.preparePermissionOptions();

    this.mapGroup();
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    async handleSubmit() {

      // check if form has any error
      if (this.$v.$invalid) {

        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });

        return false;
      }

      const response = await this.sendData();

      if (response) {

        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Group Created',
          body: `'${response.data.data.name}' Group has been updated successfully`,
          type: 'su',
          autoHideDelay: 2,
          closeCallback: () => window.location.reload()
        });

        // close modal
        this.showModal = false;
      }
    },

    async sendData() {

      this.form.submitting = true;

      const endPoint = (this.group) ? `/admin/acl/groups/${this.group.id}` : '/admin/acl/groups';
      const method = (this.group) ? 'patch' : 'post';

      const requestData = {
        name: this.form.group,
        permissions: this.form.select.permissions,
        description: this.form.description
      };

      try {

        const res = await axios[method](endPoint, requestData);

        this.form.submitting = false;
        return res;

      } catch (e) {

        this.form.submitting = false;

        // show notification about error
        NotificationHelper.showServerError(this.$snotify, e);

        // map errors to display in form
        ErrorHelper.mapServerError(this.formError, e);

        return false;
      }

    },

    preparePermissionOptions() {

      if (!this.permissions) {
        return [];
      }

      const options = [];

      for (let permission in this.permissions) {
        const groupSettings = this.permissions[permission];
        const singleOption = {id: permission, label: permission};
        singleOption.children = groupSettings.map(({id, name}) => ({id, label: name}));
        options.push(singleOption);
      }

      return options;
    },

    mapGroup() {
      // if group is present then user wants to edit a group
      if (!this.group) {
        return false;
      }

      // change modal header for group update
      this.modalTitle = 'Update Group';
      this.modalHeaderClasses = 'bg-warning text-dark';
      this.modalCloseBtnClasses = 'text-dark';
      this.submitBtnText = 'Update Group'

      // pre populate fields
      this.form.group = this.group.name;
      this.form.description = this.group.description;
      this.form.select.permissions = this.getPreSelectOptions(this.group.permissions);
    },

    getPreSelectOptions(permissions) {

      const perms = [];

      permissions.map(({id}) => perms.push(id));

      return perms;
    },

    validateProps() {
      if (this.group !== false && typeof this.group !== 'object') {
        throw new Error(`"Group" prop is required while updating group`);
      }
    }
  }
}
</script>

<style scoped>

</style>
