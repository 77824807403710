<template>
  <badge-comp
    :font-size="16"
    :type="type"
    shape="squire"
  >
    {{ content }}
  </badge-comp>
</template>

<script>
import BadgeComp from './BadgeComp';

export default {
  name: "permission-badge",
  props: {
    content: {
      type: String
    }
  },
  components: {BadgeComp},

  computed: {
    type: function () {
      const typeMap = {
        view: 'dar',
        create: 'pr',
        update: 'wa',
        delete: 'da'
      };

      if (typeMap.hasOwnProperty(this.content)) {
        return typeMap[this.content];
      }

      return 'primary'
    }
  }
}
</script>

<style scoped>
.badge {
  font-size: 16px;
}
</style>
