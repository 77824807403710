<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Available Users</div>
        </div>
        <div class="card-body">

          <div class="table-container">
            <div class="table-responsive">
              <table class="table custom-table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Groups</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="user in users"
                  :key="user.id"
                >
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>
                    <div v-if="!user.groups.length">No Group Assigned</div>
                    <div v-else v-for="(role, index) in user.groups" :key="role.name + index">
                      {{ role.name + (user.groups.length !== (index + 1) ? ',' : '')}}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      v-if="permissions.update"
                      class="btn-group dropdown"
                    >
                      <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-boundary="element"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i class="icon-menu"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item open-modal" @click.prevent="showEditModal(user.id)">
                          Update Groups
                        </a>
                      </div>
                    </div>
                    <user-action
                      :key="user.id"
                      :modal-id="getMid(user.id)"
                      :groups="groups"
                      :show="userModals[getMid(user.id)]"
                      :user="user"
                      @user-action-hide="hideEditModal(user.id)"
                    />

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatModalId } from '../../js/helpers/Helper';
import UserAction from '../DoActions/ACL/UserAction';

export default {
  name: "users-page-content",
  props: {
    users: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {UserAction},
  data() {
    return {
      userModals: {}
    }
  },
  mounted() {
    this.mapUsersToModal();
  },
  methods: {
    showEditModal(userId) {
      const mid = this.getMid(userId);
      this.userModals[mid] = true;
    },

    hideEditModal(userId) {
      const mid = this.getMid(userId);
      this.userModals[mid] = false;
    },

    mapUsersToModal() {
      const modals = {};

      this.users.map(({id}) => {
        const mid = this.getMid(id);
        modals[mid] = false;
      });

      this.userModals = modals;
    },

    getMid(id) { // model id
      return formatModalId(id);
    }
  }
}
</script>

<style scoped>

</style>
