<template>
    <span>
        <i
          v-if="showAsIcon"
          :class="iconClass"
          @click="()=>this.showHelpBlock=!showHelpBlock"
        />
        <transition name="fade">
            <div
              v-if="showHelpBlock || !showAsIcon"
              class="form-input-help"
            >
                <span
                  v-if="!showAsIcon"
                  :class="iconClass"
                  class="form-input-help-icon" />
                <span class="form-input-help-text">
                    <slot></slot>
                </span>
            </div>
        </transition>
    </span>
</template>

<script>
export default {
  name: "form-input-help",
  props: {
    icon: {
      type: [String, Boolean],
      default: false
    },
    showAsIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showHelpBlock: false
    }
  },
  computed: {
    iconClass() {
      return (this.icon) ? this.icon : 'icon-info';
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input-help {
  display: flex;
  align-items: center;

  .form-input-help-icon {
    color: #797c7f;
    font-size: 16px;
    margin: 3px 0;
  }

  .form-input-help-text {
    font-size: 11px;
    font-style: italic;
    color: #8b919b;
  }
}

//Animation classes
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
