<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Defined Permissions</div>
        </div>
        <div class="card-body">
          <div
            v-if="this.permissions.length"
            class="table-container"
          >
            <div class="table-responsive">
              <table class="table custom-table">
                <thead>
                <tr>
                  <th>Subject</th>
                  <th>Permission</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="permission in permissions">
                  <td>{{ permission.subject }}</td>
                  <td>{{ permission.name }}</td>
                  <td>{{ permission.description }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else class="lead text-center">
            There's no permissions available.
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "permissions-page-content",
  props: {
    permissions: {
      type: Array,
      required: true
    }
  }
}
</script>
