<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
        this.showModal=false;
        this.$emit('user-action-hide', id);
      }"
      @shown="populateForUpdate(charge, true)"
    >
      <form class="row">
        <div class="form-group col-12">
          <label for="distance" class="col-form-label">
            Max Distance:
            <span class="text-danger">*</span>
          </label>
          <input
            type="number"
            class="form-control"
            id="distance"
            placeholder="Maximum distance"
            v-model.trim="form.distance"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.distance" />
        </div>

        <div class="form-group col-12">
          <label for="charge" class="col-form-label">
            Charge Amount:
            <span class="text-danger">*</span>
          </label>
          <input
            type="number"
            step="0.1"
            class="form-control"
            id="charge"
            placeholder="Delivery Charge"
            v-model.trim="form.amount"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.amount" />
        </div>

        <div class="form-group col-12">
          <label for="max-order" class="col-form-label">
            Max Order:
            <span class="text-danger">*</span>
            <form-input-help>Order total exceeding this amount will lift the charge</form-input-help>
          </label>
          <input
            type="number"
            step="0.1"
            class="form-control"
            id="max-order"
            placeholder="Maximum order amount"
            v-model.trim="form.maxOrder"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.maxOrder" />
        </div>

      </form>
      <template #submit-btn-content>{{ modal.submitBtnText }}</template>
    </modal-comp>
  </div>
</template>

<script>
import { minValue, required, numeric, decimal } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';

export default {
  name: "delivery-charge-action",
  components: {FormErrorMsg, ModalComp, FormInputHelp},
  props: {
    id: {
      type: String,
      required: true
    },
    charge: {
      type: [Object, Boolean],
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'New Delivery Charge',
        headerClasses: '',
        closeBtnClasses: '',
        submitBtnText: 'Add Charge'
      },

      form: {
        distance: 0,
        amount: 0,
        maxOrder: 0,
        submitting: false
      },
      formError: {
        distance: false,
        amount: false,
        maxOrder: false
      },

      updating: false
    };
  },

  validations: {
    form: {
      distance: {
        required,
        numeric,
        minValue: minValue(1)
      },
      amount: {
        required,
        decimal,
        minValue: minValue(.5)
      },
      maxOrder: {
        required,
        decimal,
        minValue: minValue(.5)
      }
    }
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
    charge(nv) {
      if (!nv) return;

      this.populateForUpdate(nv);

      this.updating = !!nv;
    },
    // error handling
    'form.distance': function () {
      this.formError.distance = ErrorHelper.errAll(this.$v, 'form.distance');
    },
    'form.amount': function () {
      this.formError.amount = ErrorHelper.errAll(this.$v, 'form.amount');
    },
    'form.maxOrder': function () {
      this.formError.maxOrder = ErrorHelper.errAll(this.$v, 'form.maxOrder');
    }
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    async handleSubmit() {

      // check if form has any error
      if (this.$v.$invalid) {

        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });

        return false;
      }

      const response = await this.sendData();

      if (response) {

        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: `Charge ${this.updating ? 'Updated' : 'Added'}`,
          body: `Delivery Charge ${this.updating ? 'updated' : 'added'} successfully`,
          type: 'su',
          autoHideDelay: 2,
          closeCallback: () => window.location.reload()
        });

        // close modal
        this.showModal = false;
      }
    },

    async sendData() {

      this.form.submitting = true;

      const endPoint = this.updating
        ? `/admin/delivery-charge/${this.charge.id}`
        : '/admin/delivery-charge'
      ;
      const method = this.updating ? 'patch' : 'post';

      const requestData = {
        distance: parseInt(this.form.distance),
        amount: parseFloat(this.form.amount),
        max_order: parseFloat(this.form.maxOrder),
      };

      try {

        const res = await axios[method](endPoint, requestData);

        this.form.submitting = false;
        return res;

      } catch (e) {

        this.form.submitting = false;

        // show notification about error
        NotificationHelper.showServerError(this.$snotify, e);

        // map errors to display in form
        ErrorHelper.mapServerError(this.formError, e, true);

        return false;
      }

    },

    populateForUpdate(opening) {
      if (!opening) return;

      // populate data
      this.form.distance= this.charge.distance;
      this.form.amount  = this.charge.amount;
      this.form.maxOrder = this.charge.max_order;

      // customize UI
      this.modal.title = 'Update Delivery Charge';
      this.modal.submitBtnText = 'Save Changes';
      this.modal.headerClasses = 'bg-warning text-dark';
      this.modal.closeBtnClasses = 'text-dark';
    }
  }
}
</script>

<style scoped>
.hours-group {
  background-color: #9e9e9e3d;
  margin-bottom: 10px;
  border-radius: 10px;
}

.hours-group-heading {
  display: flex;
  justify-content: space-between;
}

.hours-group-heading-icon {
  font-size: 25px;
  cursor: pointer;
}
</style>
