<template>
    <div>
        <slot :click="sendDeleteRequest"></slot>
    </div>
</template>

<script>
import NotificationHelper from '../../js/helpers/NotificationHelper';

export default {
    name: "delete-action",
    props: {
        doAction: {
            type: Boolean,
            required: false
        },
        endpoint: {
            type: String,
            required: true
        },
        urlArguments: { // url arguments
            type: [String,Number,Array]
        },
        reloadAfter: {  // reloads the page after delete
            type: Boolean,
            default: true
        },
        showConfirm: { // should display confirmation before proceeding
            type: Boolean,
            default: true
        },
        confirmTitle: {
            type: String,
            default: 'Sure to delete?'
        },
        confirmBody: {
            type: String,
            default: 'This will delete it permanently, Are you sure?'
        },
        confirmYesBtnText: {
            type: String,
        },
        confirmNoBtnText: {
            type: String,
        }
    },
    data() {
        return {
            notifyMsg: {
                success: {
                    title: 'Deleted',
                    body: 'The deletion was successful',
                    type: 'wa'
                },
                error: {
                    title: 'Not Deleted!',
                    body: 'The deletion was unsuccessful',
                    type: 'er'
                },
                time: 2
            },
            submitting: false,
        }
    },
    watch: {
        doAction(nv) {
            if (nv) {
                this.sendDeleteRequest();
            }
        }
    },
    methods: {
        async sendDeleteRequest() {

            // If confirmation should be shown
            if (this.showConfirm) {

                // don't continue if confirmation was NO
                const confirm =  await this.getConfirmation();
                if (!confirm) {
                    this.$emit('cancel', false);
                    return false;
                }
            }

            const deleted = await this.sendRequest();

            this.showDeleteStatusMessage(!!deleted);
        },

        async sendRequest() {

            this.submitting = true;

            const endPoint = this.getEndPoint();

            try {

                const res = await axios.delete(endPoint);
                this.submitting = false;
                return res;

            } catch (e) {
                this.submitting = false;

                // show notification about error
                NotificationHelper.showServerError(this.$snotify, e);

                return false;
            }
        },

        getEndPoint() {
            let endpoint = `${this.endpoint}`

            if (typeof this.urlArguments === 'object') {
                this.urlArguments.map(itm => {
                    endpoint = `${endpoint}/${itm}`;
                });
            } else {
                endpoint = `${endpoint}/${this.urlArguments}`;
            }

            return endpoint;
        },

        getConfirmation() {
            return new Promise(resolve => {
                const config = {
                    title: this.confirmTitle,
                    body: this.confirmBody,
                    yesCallback: () => {
                        resolve(true);
                    },
                    noCallback: () => {
                        resolve(false);
                    },
                };

                if (this.confirmYesBtnText) {
                    config.yesBtnLabel = this.confirmYesBtnText;
                }
                if (this.confirmNoBtnText) {
                    config.noBtnLabel = this.confirmNoBtnText;
                }

                NotificationHelper.showConfirm(this.$snotify, config);
            });
        },

        showDeleteStatusMessage(isSuccess) {

            const type = isSuccess ? 'success' : 'error';

            const config = {
                title: this.notifyMsg[type].title,
                body: this.notifyMsg[type].body,
                type: this.notifyMsg[type].type,
                autoHideDelay: this.notifyMsg[type].time
            }

            NotificationHelper.showSimpleNotification(this.$snotify, {
                ...config,
                closeCallback: () => {
                    if (this.reloadAfter) {
                        window.location.reload();
                    } else {
                        this.$emit('deleted', true);
                    }
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
