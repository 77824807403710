<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">All Customers</div>
        </div>
        <div class="card-body">

          <!--list-->
          <data-table
            id="customers"
            :column-map="columnMap"
            :data="customers"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '../Utility/DataTable';
import { formatDate } from '../../js/helpers/Helper';

export default {
  name: "customers-page-content",
  props: {
    customers: {
      type: Array,
      required: true
    }
  },
  components: {DataTable},
  data() {
    return {
      columnMap: [
        {data: 'name', title: 'Customer Name'},
        {data: 'phone', title: 'Phone'},
        {data: 'email', title: 'Email'},
        {data: 'point', title: 'Points', render: (p) => p?.points || 0},
        {data: 'orders_count', title: 'Orders', render: this.renderStatus},
        {data: 'created_at', title: 'Joined', render: this.renderFormattedTime},
      ],
    }
  },
  methods: {

    renderFormattedTime(timeString) {
      return formatDate(timeString, 'Do MMM @hh:mm A');
    }

  },
}
</script>

<style>
.table-badge {
  font-size: 12px;
}

#orders-table td {
  font-size: 16px;
  font-weight: 600;
}

.reservation-action {
  cursor: pointer;
}
</style>
