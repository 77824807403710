import { render, staticRenderFns } from "./SettingAction.vue?vue&type=template&id=392dc7b2&scoped=true&"
import script from "./SettingAction.vue?vue&type=script&lang=js&"
export * from "./SettingAction.vue?vue&type=script&lang=js&"
import style0 from "./SettingAction.vue?vue&type=style&index=0&id=392dc7b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392dc7b2",
  null
  
)

export default component.exports