<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Entertainments</div>
          <entertainment-action
            v-if="permissions.create"
            id="addEntertainment"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </entertainment-action>
        </div>
        <div class="card-body">

          <!--                    list-->
          <data-table
            id="entertainments"
            :column-map="columnMap"
            :data="entertainments"
            @actionClicked="handleAction"
          />

          <!--actions-->
          <entertainment-action
            id="editEntertainment"
            :entertainment="actionSubject.subject"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />


          <delete-action
            confirm-body="This will delete the Entertainment permanently."
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/entertainment"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import DataTable from '../Utility/DataTable';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';
import { formatDate } from '../../js/helpers/Helper';
import EntertainmentAction from "../DoActions/EntertainmentAction.vue";

export default {
  name: "entertainment-page-content",
  props: {
    entertainments: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {EntertainmentAction, DeleteAction, BadgeComp, DataTable},
  data() {
    return {
      columnMap: [
        {data: 'image', title: 'Image', render: this.renderImage},
        {data: 'start_date', title: 'Start', render: this.renderDate},
        {data: 'end_date', title: 'End', render: this.renderDate},
        {data: 'status', title: 'Status', render: this.renderStatus},
        {data: 'id', title: 'Actions', render: this.renderAction},
      ],

      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.entertainments.find(itm => itm.id === id),
        action: action,
      };
    },

    renderImage(img) {
      return `<img class="rounded img-thumbnail img-48" src="/storage/entertainment/${img}" alt="ent img" />`
    },

    renderShow(data) {

      if (data) {
        return `<span class="badge table-badge badge-primary badge-pill">Shown</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Hidden</span>`
    },

    renderStatus(data) {

      if (data) {
        return `<span class="badge table-badge badge-primary badge-pill">Enabled</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Disabled</span>`
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param data
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(data, type, row) {

      let actions = '';

      if (this.permissions.update) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-edit1"></i>
          </span>
        `;
      }

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      return actions;
    },

    renderDate(data) {
      return formatDate(data);
    },

    renderAmount(data, d, row) {
      const price = data.toFixed(2);
      return (row.fixed) ? price : `${price} %`;
    }
  }
}
</script>

<style>
.table-badge {
  font-size: 12px;
}
</style>
