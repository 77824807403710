<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
          this.showModal=false;
          this.$emit('user-action-hide', id);
      }"
    >
      <form class="row">

        <div class="form-group col-12">
          <label class="col-form-label">
            Select Image:
            <span class="text-danger">*</span>
            <span class="text-info small"> Max file Size 200 KB </span>
          </label>
          <div>
            <div class="custom-file">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="upload Image"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  @click="triggerClick('uploadMedia')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    @click="triggerClick('uploadMedia')"
                  >
                    <input
                      ref="uploadMedia"
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      class="d-none"
                      @change="handleFileUpload"
                    />
                    Choose File
                  </button>
                </div>
              </div>
              <form-error-msg :error="formError.image"/>
            </div>
            <div
              v-if="form.image"
              class="preview mt-2"
            >
              <div class="card">
                <div class="card-img-top user-uploaded-image">
                  <img
                    class="img-fluid user-image"
                    :src="filePreview(form.image)"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-12">
          <label class="col-form-label">
            Select Validity Period:
            <span class="text-danger">*</span>
          </label>
          <vue-date-picker
            id="from-date"
            v-model="form.dates"
            color="#007ae1"
            validate
            fullscreen-mobile
            range
            placeholder="Click to select"
          />
          <form-error-msg :error="formError.dates.start"/>
          <form-error-msg :error="formError.dates.end"/>
        </div>

        <!--status-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Status:
            <form-input-help>Disabling will make this unusable</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.status"
              enable-text="Enabled"
              disable-text="Disabled"
              @change="val=>form.status=val"
            />
          </div>
        </div>

      </form>

      <template #submit-btn-content>
        <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
        {{ modal.submitBtnText }}
      </template>
    </modal-comp>
  </div>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import SwitchComp from '../Utility/SwitchComp';
import {VueDatePicker} from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import moment from 'moment';
import {DATE_SELECTS_FORMAT} from '../../js/helpers/Constants';

export default {
  name: "entertainment-action",
  components: {FormErrorMsg, ModalComp, FormInputHelp, SwitchComp, VueDatePicker},
  props: {
    id: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    entertainment: {
      type: [Object, Boolean]
    },
  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'New Entertainment',
        headerClasses: '',
        closeBtnClasses: '',
        submitBtnText: 'Create'
      },
      editing: false,

      form: {
        image: null,
        dates: {
          start: '',
          end: ''
        },
        status: true,
        submitting: false
      },

      formError: {
        image: false,
        dates: {
          start: false,
          end: false
        },
      }
    };
  },

  validations: {
    form: {
      image: {
        required: requiredIf('typeCoupon'),
      },
      dates: {
        start: required,
        end: required,
      }
    }
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
    entertainment(nv) {

      this.editing = !!nv;

      this.populateForUpdate(nv);

    },

    // error handling
    'form.image': function () {
      this.formError.image = ErrorHelper.errAll(this.$v, 'form.image');
    },
    // 'form.dates.start': function () {
    //   this.formError.dates = ErrorHelper.errAll(this.$v, 'form.dates.start');
    // },
    // 'form.dates.end': function () {
    //   this.formError.dates = ErrorHelper.errAll(this.$v, 'form.end_date');
    // },
  },

  mounted() {
    this.setStartDate();
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    triggerClick(name) {
      let elem = this.$refs[name];
      elem.click();
    },

    handleFileUpload() {
      this.form.image = this.$refs.uploadMedia.files[0];
    },

    async handleSubmit() {

      // check if form has any error
      if (this.$v.$invalid) {

        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });

        return false;
      }

      const response = await this.sendData();

      if (response) {

        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: `Entertainment ${!this.editing ? 'Created' : 'Updated'}`,
          body: `Entertainment ${!this.editing ? 'created' : 'updated'} successfully`,
          type: 'su',
          autoHideDelay: 2,
          closeCallback: () => window.location.reload()
        });

        // close modal
        this.showModal = false;
      }
    },

    async sendData() {

      this.form.submitting = true;

      const endPoint = (this.editing)
        ? `/admin/entertainment/${this.entertainment.id}`
        : `/admin/entertainment`
      ;

      const reqPayload = new FormData();
      reqPayload.append('start_date', this.form.dates.start);
      reqPayload.append('end_date', this.form.dates.end);
      reqPayload.append('status', this.form.status ? '1' : '0');

      if (this.form.image instanceof File) {
        reqPayload.append('image', this.form.image);
      }

      if (this.editing) reqPayload.append('_method', 'PATCH')

      try {
        const res = await axios.post(endPoint, reqPayload);

        this.form.submitting = false;
        return res;

      } catch (e) {

        this.form.submitting = false;

        // show notification about error
        NotificationHelper.showServerError(this.$snotify, e);

        // map errors to display in form
        ErrorHelper.mapServerError(this.formError, e, true);

        const errPayload = e.response?.data?.errors;
        if (errPayload) {
          this.formError.dates.start = errPayload.start_date?.[0] ?? false;
          this.formError.dates.end = errPayload.end_date?.[0] ?? false;
        }

        return false;
      }

    },

    setStartDate() {
      const date = moment();
      const today = date.format(DATE_SELECTS_FORMAT);
      const weeksLastDay = date.clone().endOf('week').format(DATE_SELECTS_FORMAT);

      this.form.dates.start = today;
      this.form.dates.end = weeksLastDay;
    },

    populateForUpdate(nv) {

      if (!nv) return;

      // customize modal
      this.modal = {
        title: 'Update Entertainment',
        headerClasses: 'bg-warning text-dark',
        closeBtnClasses: 'text-dark',
        submitBtnText: 'Save Changes'
      };

      // populate values
      this.form.image = nv.image;
      this.form.dates.start = nv.start_date;
      this.form.dates.end = nv.end_date;
      this.form.status = nv.status;
    },

    filePreview(img) {
      if (!img) return;
      if (typeof img == 'string') return `/storage/entertainment/${img}`;

      return URL.createObjectURL(img);
    }
  }
}
</script>
<style scoped>
.user-uploaded-image {
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: auto;
  justify-content: center;
}

.user-image {
  max-height: 300px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  margin-top: 15px;
  border: 1px solid lightgray;
}
</style>
